<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { orderStatusColor } from '@/utils/OrderStatus'
import OrderEventCard from '@/pages/calendar/OrderEventCard.vue'

export default {
  ...dashboardPageCommonAttributes,
  name: 'OrderCalendarPage',
  components: { OrderEventCard },
  data: function () {
    return {
      loading: false,
      calendar: {
        value: '',
        color: 'primary',
        type: 'month',
        types: [
          { value:'month',text:'Месяц' },
          { value:'week',text:'Неделя' },
          { value:'day',text:'День' },
          { value:'4day',text:'4 дня' }
        ],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [1, 2, 3, 4, 5, 6, 0],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']
      },
      orderStatusTemplates: [
        { value: null, text: 'Все' },
        { value: 'active', text: 'В работе' },
        { value: 'completed', text: 'Завершенные' }
      ],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false
    }
  },
  computed:{
    ...mapState('order_calendar', ['calendarType','busy','orderStatusTemplate','managerId']),
    ...mapState('order_calendar', {
      events: function(state) {
        return state.items
          .map((o) => {
            const [beginAt, endAt] = o.deliveryTimeSlot.split('_')
            const start = new Date(o.deliveryDate)
            const end = new Date(o.deliveryDate)

            start.setHours(parseInt(beginAt))
            end.setHours(parseInt(endAt))

            let name = o.code

            if (o.deliveryType === 'Delivery') {
              name = `🚚 ${name}`
            }

            return {
              timed: true,
              name,
              color: orderStatusColor(o.status),
              start,
              end,
              order: o
            }
          })
      },
      managers:function(state) {
        return state.users
          .map((u) => { return {
            text: `${u.fullName}`, value: u.id
          }} )
      }
    }),
    ...mapState('auth', ['principal'])
  },
  created() {
    this.calendar.type = this.calendarType
    // setTimeout(() => {
    //   this.loading = false
    // }, 100)
  },
  methods: {
    ...mapActions('order_calendar',['fetchData']),
    ...mapMutations('order_calendar', {
      setCalendarType: 'SET_CALENDAR_TYPE',
      setOrderStatusTemplate: 'SET_ORDER_STATUS_TEMPLATE',
      setManager: 'SET_MANAGER_ID'
    }),
    changeCalendarType() {
      console.log('Set calendar type to',this.calendar.type)
      this.setCalendarType({ type: this.calendar.type })
    },
    changeStatusTemplate(value) {
      this.setOrderStatusTemplate({ statusTemplate: value })
      this.reloadCalendar()
    },
    changeManager(value) {
      this.setManager({ managerId: value })
      this.reloadCalendar()
    },
    changeManagerToMyself(value) {
      this.setManager({ managerId: this.principal })
      this.reloadCalendar()
    },
    reloadCalendar() {
      const start = this.$refs.calendar.lastStart
      const end = this.$refs.calendar.lastEnd

      console.log('reloadCalendar', start, end)
      this.getEvents({ start,end })
    },
    getEvents({ start, end }) {
      // console.log(start.date, end.date)
      // console.log(this.$refs.calendar.lastStart.date, this.$refs.calendar.lastEnd.date)
      this.fetchData({ start: start.date, end: end.date })
    },
    viewDay ({ date }) {
      console.log('viewDay', date)
      this.setCalendarType({ type: 'day' })
      this.calendar.type = this.calendarType
    },
    setToday () {
      this.calendar.value = ''
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Календарь'
      }
    }
  }
}
</script>

<template>
  <div>
    <h1 v-if="$refs.calendar" class="text-h4">{{ $refs.calendar.title }}</h1>
    <h1 v-else class="text-h4">Календарь</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-card-actions>
        <v-row dense>
          <v-col cols="6" md="2" order="3" order-md="1">
            <div>
              <v-btn
                fab
                text
                small
                @click="$refs.calendar.prev()"
              >
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                fab
                small
                @click="setToday"
              >
                <v-icon>mdi mdi-calendar-check-outline</v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                @click="$refs.calendar.next()"
              >
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" md="3" order="4" order-md="2">
            <v-select
              v-model="calendar.type"
              :items="calendar.types"
              dense
              outlined
              hide-details
              label="Вид"
              @change="changeCalendarType"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" order="2" order-md="3">
            <v-select
              :value="managerId"
              outlined
              dense
              label="Менеджер"
              persistent-hint
              clearable
              :items="managers"
              :loading="busy"
              prepend-inner-icon="mdi-account-box-outline"
              @click:prepend-inner="changeManagerToMyself"
              @change="changeManager"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="3" order="1" order-md="4">
            <v-select
              :value="orderStatusTemplate"
              :items="orderStatusTemplates"
              dense
              outlined
              hide-details
              label="Заказы"
              @change="changeStatusTemplate"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="calendar.value"
            color="success"
            locale="ru-ru"
            :weekdays="calendar.weekday"
            :type="calendarType"
            :events="events"
            :event-overlap-mode="calendar.mode"
            :event-overlap-threshold="30"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="getEvents"
          />
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <order-event-card v-model="selectedOpen" :event="selectedEvent"></order-event-card>
          </v-menu>
        </v-sheet>
      </v-card-text>
    </v-card>
    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
