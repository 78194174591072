<script>
export default {
  name: 'OrderEventCard',
  props: {
    event: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  computed:{
    order() {
      return this.event.order
    }
  },
  watch:{
    'event'(newValue) {
      console.log(newValue.order.manager)
      console.log(newValue.order.worker)
    }
  },
  methods: {
    onCancelAction() {
      this.$emit('input', false)
    }
  }
}
</script>

<template>
  <v-card
    outlined
    min-width="350px"
    tile
  >
    <!--    <v-card-title><v-chip :color="event.color">{{ event.name }}</v-chip></v-card-title>-->
    <!--    <v-card-text>-->
    <!--      <span v-html="event.details"></span>-->
    <v-card-text>
      <div class="d-flex flex-column">
        <div class="d-flex align-center mb-1"><v-chip :color="event.color">{{ event.name }}</v-chip>
          <span class="ml-2">{{ order.status | orderStatusTitle }}</span>
        </div>
        <div class="text-caption">От {{ order.orderDate }}</div>
        <div class="text-caption">Выполнить {{ order.deliveryDate }} {{ order.deliveryTimeSlot | timeSlot }}</div>
        <div class="text-caption">Тип {{ order.deliveryType }}</div>
        <div class="text-caption">Заказчик: {{ order.customerName }}</div>
        <div v-if="order.manager" class="text-caption">Менеджер: {{ order.manager.fullName }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        small
        outlined
        text
        @click="onCancelAction"
      >
        Закрыть
      </v-btn>
      <v-btn
        small
        outlined
        text
        :to="{name: 'order.view', params: {id: order.id}}"
      >
        К заказу
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>
